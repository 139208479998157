import styled from 'styled-components'

export const Container = styled.div`
  ol {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 80.5rem;
    margin: 0 auto;
    li:not(:first-child) {
      div {
        &:after {
          content: "";
          position: absolute;
          z-index: -10;
          transform: translateX(-50%);
          width: 100%;
          border: 1px solid #515151;
          background-color: #515151;
        }
      }
      .previous, .current {
        &:after {
          border: 1px solid #018762;
          background-color: #018762;
        }
      }
    }
  }
`
export const StepItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  color: #515151;

  .previous,
  .current {
    & + span {
      color: #018762;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #515151;
    border-radius: 9999px;
    background-color: #ffffff;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 8px;
  }
  .previous {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #018762;
    border-radius: 9999px;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #018762;
    color: #ffffff;
    span {
      display: flex;
      align-items: center;
    }
  }
  .current {
    border-color: #018762;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #018762;
      border-radius: 9999px;
      width: 1.875rem;
      height: 1.875rem;
      background-color: #018762;
      color: #ffffff;
    }
  }
`