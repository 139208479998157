import React from "react"
import * as Styled from "./styles"
import Check from '../../../assets/icons/Check';

export const Stepper = ({steps, currentStepNumber, className}) => {
    return (
      <Styled.Container className={className}>
        <ol>
          {steps.map((step, index) => {
            const stepNumber = ++index
            const isCurrentStep = stepNumber === currentStepNumber
            const isPreviousStep = stepNumber < currentStepNumber
            const stepType = isCurrentStep  ? 'current' : isPreviousStep ? 'previous' : ""
            return <Step key={index} title={step} type={stepType} stepNumber={stepNumber} currentStep={currentStepNumber}/>
          })}
        </ol>
      </Styled.Container>
    )
}

export const Step = ({title, stepNumber, className, type }) => {
  return (
      <Styled.StepItem>
        <div className={`${type} ${className}`}>
          <span>{type === 'previous' ? <Check/> : stepNumber}</span>
        </div>
        <span>{title}</span>
      </Styled.StepItem>
    )
}