import * as React from "react"

function CameraIcon() {
  return (
    <svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 14.5c1.25 0 2.313-.437 3.188-1.312S14.5 11.25 14.5 10c0-1.25-.437-2.313-1.312-3.188S11.25 5.5 10 5.5c-1.25 0-2.313.437-3.188 1.312S5.5 8.75 5.5 10c0 1.25.437 2.313 1.312 3.188S8.75 14.5 10 14.5zm0-2c-.7 0-1.292-.242-1.775-.725C7.742 11.292 7.5 10.7 7.5 10s.242-1.292.725-1.775C8.708 7.742 9.3 7.5 10 7.5s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725zM2 18c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 010 16V4c0-.55.196-1.02.588-1.412A1.923 1.923 0 012 2h3.15L7 0h6l1.85 2H18c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0118 18H2zm16-2V4h-4.05l-1.825-2h-4.25L6.05 4H2v12h16z"
        fill="#0B7944"
      />
    </svg>
  )
}

export default CameraIcon