import React,{ useState } from "react"
import ImagemPlaceHolder from "../../assets/icons/ImagePlaceholder.jsx"
import Header from "../../components/profissionaisSaude/Header/index"
import Footer from "../../components/profissionaisSaude/Footer/index"
import styled from "styled-components"
import { Stepper} from "../../components/profissionaisSaude/stepper"

import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import UploadIcon from "../../assets/icons/UploadIcon"
import CameraIcon from "../../assets/icons/CameraIcon"

import { Link } from "gatsby"

import Documento from '../../assets/images/documento.svg'


const Image = styled(Documento)`

`
const ImageCredit = styled.span`
color: #515151;
font-size: 0.675rem;
position: relative;
left: -5rem;
top: -1rem;

a{
  text-decoration: none;
}
`

const ImageCreditLink = styled.a`
text-decoration: underline;
`

const Container = styled.div`
  width: 100%;
  max-width: 68.75rem;
  margin: 3rem auto 4.25rem auto;

  ol{
    width: 25rem;
  }

  .titles-field{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin:2rem 0 1rem; 

    h1{
      font-size: 2rem;
      font-weight: bold;
    }

    h3 {
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .brief{
    margin-bottom: 2rem;

    h3{
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  .professional-data{
    margin-bottom: 2rem;

     h3{
        font-size: 1.125rem;
        font-weight: bold;
        margin-bottom: 1rem;
     }
     .input-field{
      display: grid;
      grid-template-columns: 23% 49% 23%;
      justify-content: space-between;
      
      .focusGreen:has(input:focus){
        border: 1.2px solid #018762 ;

      }
     }
  }

  .verification-section {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
    h3{
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
 
  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;


    button {
      border: 2px solid #018762;
      border-radius: 8px;
      width: 10.375rem;
      height: 2.9375rem;
      box-shadow: 0px 4px 4px 1px  rgba(0, 0, 0, 0.25);
    }
    .exit-button{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.625rem 1rem;
      font-size: 1.125rem;
      font-weight: bold;
      color: #018762;
      border: 2px solid #018762;
      border-radius: 8px;
      width: 10.375rem;
      height: 2.9375rem;
      box-shadow: 0px 4px 4px 1px  rgba(0, 0, 0, 0.25);

    }
    .next-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0.625rem 1rem;
      border: 2px solid #018762;

      border-radius: 8px;
      width: 10.375rem;
      height: 2.9375rem;
      font-size: 1.125rem;
      font-weight: bold;
      background: #018762;
      color: #fff;

      box-shadow: 0px 4px 4px 1px  rgba(0, 0, 0, 0.35);
    }
  }
  .private-data{
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: flex-end;
  }
  .buttons-container-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    align-items: flex-end;
    .image-placeholder {
      position: relative;
      width: 10rem;
      display: flex;
      align-items: center;
      justify-content: center; 

      border: 3px solid transparent;
      background-image: linear-gradient(125deg,#fff,#fff), 
      linear-gradient(125deg, #018383, #014687);
      background-origin: border-box;
      background-clip: content-box, border-box ;
    
    }
    .rounded{
      height: 10rem;
      border-radius: 100%;
    }
    .square{
      height: 7.75rem;
      border-radius:10px;
    }
    .image-buttons {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      
      .button-icon-action {
        border: 1px solid #018762;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        font-size: 0.875rem;

        color: #018762;
        padding: 0.625rem 1rem;
        border-radius: 8px;
        width: 9.875rem;
        height: 3rem;
       
      }
    }
  }
  .textarea-box{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    p{
      font-weight: 700;
      font-size: 1rem;
      color: #515151;
    }
  }
  .text-description {
    resize: none;
    color: 515151;
    border-radius: 10px;
    width: 100%;
    height: 7.75rem;
    border: 1px solid black;
    padding: 1.0625rem 1rem;

    &::placeholder{
      color: #cfcfcf;
      font-size: 1rem;
      font-weight: 200;  
    }
  }
  .textarea-box-validate{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #E6EEF8;
    padding: .625rem 1.875rem .625rem 3rem  ;
    border-radius: .625rem;
    position: relative;
    font-weight: 700;

    .img-box{
      position: absolute;
      left: 465px;
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .text-validaded{
    border: none;
    max-width: 24.375rem;
    height: 6.4375rem;
    padding: 0;
    display: flex;
    align-items: center;
 
    &::placeholder{
        color: #000;
        font-weight: 700;
        font-size: .875rem;
    }
  }
`

export default function CadastroDadosPessoais() {

  const titles = ["Dados Profissionais", "Atendimento"]
  const [step, setStep] = useState(1)

  return (
    <>
      <Header />
      <Container>
      <Stepper
       steps={titles}
       currentStepNumber={step}
            />
        <section>

            <div className="titles-field">
              <h1>Dados profissionais</h1>
                
              <h3>Foto do perfil</h3>
            </div>
          <form>
            <fieldset className="private-data">
              <div className="buttons-container-profile">
                <div className="image-placeholder rounded">
                    <ImagemPlaceHolder />
                </div>
                <div className="image-buttons">
                  <button className="button-icon-action">
                    <UploadIcon />
                    Carregar foto
                  </button>
                  <button className="button-icon-action">
                    <CameraIcon />
                    Abrir câmera
                  </button>
                </div>
              </div>
              
              <div className="textarea-box">

              <p>Descrição da imagem</p>
              <textarea
                placeholder="Exemplo: Mulher parda, esboçando um sorriso. Olhos e cabelos castanhos com mechas, usando um óculos de grau com armação preta, vestida com uma blusa de manga longa na cor preta. No fundo há um quadro com a foto de um cachorro sem raça definida na cor caramelo."
                className="text-description"
                name="Dados Pessoais"
              />
              </div>
            </fieldset>
            <fieldset className="brief ">
              <h3>Resumo profissional</h3>
              <textarea
                className="text-description"
                placeholder="Faça um resumo profissional sobre você. Conte sobre sua formação, trajetória e outras informações que você quer destacar."
                name="Dados Pessoais"
              />
            </fieldset>
            <fieldset className="professional-data">
              <h3>Dados Profissionais</h3>
              <div className="input-field">
                <TextInput
                  type="text"
                  className="focusGreen"
                  label="Número da Inscrição Profissional"
                  disabled
                />
                <TextInput
                  type="text"
                  className="focusGreen"
                  label="Especialidade"
                  placeholder="Inserir nome da especialidade caso possuir"
                />
                <TextInput
                  type="text"
                  className="focusGreen"
                  label="RQE"
                  placeholder="Inserir número"
                />
              </div>
            </fieldset>
            <fieldset className="verification-section">
              <h3>Foto de Verificação</h3>
              <div className="private-data">
                <div className="buttons-container-profile">
                  <div className="image-placeholder square">
                      <ImagemPlaceHolder />
                  </div>
                  <div className="image-buttons">
                    <button className="button-icon-action">
                      <UploadIcon />
                      Carregar foto
                    </button>
                    <button className="button-icon-action">
                      <CameraIcon />
                      Abrir câmera
                    </button>
                  </div>
                </div>
              
                <div className="textarea-box-validate">
                  <textarea
                    disabled
                    className="text-description text-validaded"
                    placeholder="Para verificar seu cadastro, será necessário enviar uma        
                    selfie segurando sua cédula de identidade profissional:                         
                    ✓ Enquadre apenas o seu rosto e documento;                               
                    ✓ Não enviar a foto invertida ou desfocada;                               
                    ✓ Confira se está legível antes do envio."
                    name="Dados Pessoais"
                  />
                  <div className="img-box">
                  <ImageCredit>
                    Ilustrado por{" "}
                    <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
                      Freepik
                    </ImageCreditLink>
                  </ImageCredit>
                  <Image/>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="buttons-container">
              <Link to="/saude/painel-inicial/" className="exit-button">Voltar</Link>
              <Link to="/saude/cadastro-dados-atendimento" className="next-button">Próximo</Link>
            </div>
          </form>
        </section>
      </Container>
      <Footer hasButtonUp />
    </>
  )
}
