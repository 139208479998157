import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M6.08 16c.234 0 .441-.046.621-.14.18-.093.334-.234.46-.42l8.572-13.284c.098-.14.168-.275.207-.404.04-.128.06-.251.06-.368 0-.318-.102-.578-.305-.78-.204-.2-.467-.301-.79-.301-.22 0-.405.044-.555.133-.15.09-.29.239-.421.45l-7.884 12.44-4.009-5.02c-.253-.318-.562-.477-.927-.477-.323 0-.588.102-.797.305A1.047 1.047 0 000 8.917c0 .14.026.277.077.41.052.134.136.271.253.411l4.669 5.73c.295.355.655.533 1.08.533z"
      ></path>
    </svg>
  );
}

export default CheckIcon;