

import * as React from "react"

function UploadIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 16c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 010 14v-3h2v3h12v-3h2v3c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0114 16H2zm5-4V3.85l-2.6 2.6L3 5l5-5 5 5-1.4 1.45L9 3.85V12H7z"
        fill="#0B7944"
      />
    </svg>
  )
}
export default UploadIcon