import * as React from "react"

function ImagePlaceholder() {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      
    >
      <path fill="url(#pattern0)" d="M0 0H32V32H0z" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#image0_16464_57315" transform="scale(.03333)" />
        </pattern>
        <image
          id="image0_16464_57315"
          width={30}
          height={30}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAACk0lEQVRIie2Wu2uTURjGf8/XJN6ouFjw0kldpc7qJIJSx+qiRZpoFUQ3samCcWkqHYVia5OK4KCIi0pB0MHbIAgO6iB1UOsf0Lp4Sb7HIZeGNCmJaZz8wQffec/7nue877lw4D//aRMq/40memVPAFvapDVnMchQdgYgKM/Avt5GUYCtMhOlRlDZ0UbREt21hFeSR87ntjoIuoGZWg5tEXYQnObSrW9cmJpz3qf+mXAjRNoxqMJw0ldPDPI7L3VospbPshkLxt0RbnIY2SwYb0L7oMLwqzr0BThQy6FuxoaUk9krpXYIZ0gnvgiPNjGButTM2HCFCtEyycxVw1L7Ii+NT1rhTiuywau+rzVsszgOPC4MXaB8cykdLxudzIrlSMdTgssVls/GcZLTT5eNG0nsZjjzEv52cyWzKafjCC5jXvvXz/2kbi8w1t8V5GLnLHqxdxS9P8p+FEZz1zhfEIVWjlMymzI641ikl9TtBdKJw8pFZ40vYvcA64rfLkuXlIvOMjLQVwr/u1JXk04cFr5TOV4dbPsIw9P3Wr9Axvq7hDPVopZ3W+yt8pakDCMDG1u+QIJc7Jxx55KOoelXAKTj1T3rA+lsyxkbH2o+hkM117ih4J/hGlI3fygdXwA6oVDecqbVjMb3yDwvtr5XZjzX1LRXx1YtsYVB/c0VhpV9Lq+xxaDMJI0+CPL5bmAe6VPx+CD5RWlNSyejTiU/LW6uoeyMK14IjaIwfGipp6kY60Fz57UWY/1dykVnKa5zCVt7IB9IwbOqiHlHI9tbFwYYGeiTdJeGLpCgj+Gp+x0rIvzk7Qf29byXdABYuukKzNvBUYan7gOsjPCieCaQfiA6KZT+F+KdrBuORY5x4cabkvsfrqv0hQxcK8gAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}

export default ImagePlaceholder
